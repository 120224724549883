$my_primary_palette: (
  50 : #eaf0f6,
  100 : #cadae9,
  200 : #a7c2db,
  300 : #84aacc,
  400 : #6997c1,
  500 : #4f85b6,
  600 : #487daf,
  700 : #3f72a6,
  800 : #36689e,
  900 : #26558e,
  A100 : #cfe3ff,
  A200 : #9cc6ff,
  A400 : #69a9ff,
  A700 : #4f9aff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my_secondary_palette: (
  50 : #e3e8ea,
  100 : #b9c5ca,
  200 : #8b9fa7,
  300 : #5d7984,
  400 : #3a5c69,
  500 : #173f4f,
  600 : #143948,
  700 : #11313f,
  800 : #0d2936,
  900 : #071b26,
  A100 : #63bfff,
  A200 : #30aaff,
  A400 : #0095fc,
  A700 : #0086e2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my_accent_palette: (
  50 : #eef7fc,
  100 : #d4ebf7,
  200 : #b8def1,
  300 : #9bd0eb,
  400 : #85c6e7,
  500 : #70bce3,
  600 : #68b6e0,
  700 : #5daddc,
  800 : #53a5d8,
  900 : #4197d0,
  A100 : #ffffff,
  A200 : #eff8ff,
  A400 : #bce3ff,
  A700 : #a2d8ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


$my_danger_palette: (
  50 : #f9e6e6,
  100 : #efc1c1,
  200 : #e59898,
  300 : #db6e6e,
  400 : #d34f4f,
  500 : #cb3030,
  600 : #c62b2b,
  700 : #be2424,
  800 : #b81e1e,
  900 : #ac1313,
  A100 : #cb3030,
  A200 : #efc1c1,
  A400 : #b81e1e,
  A700 : #cb3030,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my_success_palette: (
  50 : #f2f8ef,
  100 : #dfedd8,
  200 : #c9e2be,
  300 : #b3d6a4,
  400 : #a3cd91,
  500 : #93c47d,
  600 : #8bbe75,
  700 : #80b66a,
  800 : #76af60,
  900 : #64a24d,
  A100 : #ffffff,
  A200 : #daffcd,
  A400 : #b4ff9a,
  A700 : #a1ff80,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my_info_palette: (
  50 : #eef5fb,
  100 : #d4e5f5,
  200 : #b7d4ee,
  300 : #9ac2e7,
  400 : #85b5e1,
  500 : #6fa8dc,
  600 : #67a0d8,
  700 : #5c97d3,
  800 : #528dce,
  900 : #407dc5,
  A100 : #ffffff,
  A200 : #e3efff,
  A400 : #b0d3ff,
  A700 : #96c4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my_warn_palette: (
  50 : #fffaed,
  100 : #fff4d1,
  200 : #ffecb3,
  300 : #ffe494,
  400 : #ffdf7d,
  500 : #ffd966,
  600 : #ffd55e,
  700 : #ffcf53,
  800 : #ffca49,
  900 : #ffc038,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #fff5e1,
  A700 : #ffedc8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my_dark_palette: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my_light_palette: (
  50 : #fcfcfc,
  100 : #f7f7f7,
  200 : #f2f2f2,
  300 : #ededed,
  400 : #e9e9e9,
  500 : #e5e5e5,
  600 : #e2e2e2,
  700 : #dedede,
  800 : #dadada,
  900 : #d3d3d3,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


