@import '@taiga-ui/core/styles/taiga-ui-local';

:root {
  // Fonts
  --tui-heading-font: var(--m4p-font-family);
  --tui-text-font: var(--m4p-font-family);

  --tui-font-text: var(--tui-text-font);
  --tui-font-text-xl: normal var(--m4p-fs-xl)/var(--m4p-lh-xl) var(--tui-font-text);
  --tui-font-text-l: normal var(--m4p-fs-l)/var(--m4p-lh-l) var(--tui-font-text);
  --tui-font-text-ui-l: normal var(--m4p-fs-l)/var(--m4p-lh-l-2) var(--tui-font-text);
  --tui-font-text-m: normal var(--m4p-fs-m)/var(--m4p-lh-m) var(--tui-font-text);
  --tui-font-text-ui-m: normal var(--m4p-fs-m)/var(--m4p-lh-m-2) var(--tui-font-text);
  --tui-font-text-s: normal var(--m4p-fs-s)/var(--m4p-lh-s) var(--tui-font-text);
  --tui-font-text-ui-s: normal var(--m4p-fs-s)/var(--m4p-lh-s-2) var(--tui-font-text);
  --tui-font-text-xs: normal var(--m4p-fs-xs)/var(--m4p-lh-xs) var(--tui-font-text);

  // Text
  --tui-text-primary: var(--m4p-dark-500);
  --tui-text-secondary: var(--m4p-dark-300);
  --tui-text-tertiary: var(--m4p-dark-200);

  // Colors
  --tui-background-accent-1: var(--m4p-primary-500);
  --tui-background-accent-1-hover: var(--m4p-primary-700);
  --tui-background-accent-1-pressed: var(--m4p-primary-400);
  --tui-text-primary-on-accent-1: var(--m4p-primary-contrast-500);


  --tui-background-accent-2: var(--m4p-accent-500);
  --tui-background-accent-2-hover: var(--m4p-accent-700);
  --tui-background-accent-2-pressed: var(--map-accent-400);
  --tui-text-primary-on-accent-2: var(--m4p-accent-contrast-500);

  // Link
  --tui-text-action: var(--m4p-primary-400);
  --tui-text-action-hover: var(--m4p-secondary-400);

  // Selection
  --tui-service-selection-background: var(--m4p-primary-500);

  // Alerts
  --tui-status-positive: var(--m4p-success-600);
  --tui-status-positive-pale: var(--m4p-success-100);
  --tui-status-positive-pale-hover: var(--map-success-200);
  --tui-status-info: var(--m4p-info-600);
  --tui-status-info-pale: var(--m4p-info-100);
  --tui-status-info-pale-hover: var(--map-info-200);
  --tui-status-negative: var(--m4p-danger-600);
  --tui-status-negative-pale: var(--m4p-danger-50);
  --tui-status-negative-pale-hover: var(--m4p-danger-100);
  --tui-status-warning: var(--m4p-warn-900);
  --tui-status-warning-pale: var(--m4p-warn-100);
  --tui-status-warning-pale-hover: var(--m4p-warn-200);

  // Positive - Negative
  --tui-text-positive: var(--m4p-success-400);
  --tui-text-positive-hover: var(--m4p-success-200);
  --tui-text-negative: var(--m4p-danger-400);
  --tui-text-negative-hover: var(--m4p-danger-200);


}

[tuiAppearance][data-appearance="secondary"]:is(a, button, select, textarea, input, label):active:not(:disabled):not([data-state]) {
  background: var(--m4p-secondary-600);
  color: var(--m4p-secondary-contrast-600);
}


[tuiAppearance][data-appearance="secondary"]:is(a, button, select, textarea, input, label):hover:not(:disabled):not([data-state]) {
  background: var(--m4p-secondary-500);
  color: var(--m4p-secondary-contrast-500);
}

[tuiAppearance][data-appearance='secondary'] {
  background: var(--m4p-secondary-400);
  color: var(--m4p-secondary-contrast-400);


  .appearance-hover {
    background: var(--m4p-secondary-500);
    color: var(--m4p-secondary-contrast-500);
  }

  .appearance-active {
    background: var(--m4p-secondary-600);
    color: var(--m4p-secondary-contrast-600);
  }

  &:checked,
  &:indeterminate {
    background: var(--m4p-secondary-400);
    color: var(--m4p-secondary-contrast-400);

    .appearance-hover {
      background: var(--m4p-secondary-500);
      color: var(--m4p-secondary-contrast-500);

    }

    .appearance-active {
      background: var(--m4p-secondary-600);
      color: var(--m4p-secondary-contrast-600);
    }

    &:invalid {
      background: var(--tui-status-negative);
      color: #fff;

      .appearance-hover {
        background: var(--tui-status-negative);
        color: #fff;
      }

      .appearance-active {
        background: var(--tui-status-negative);
        color: #fff;
      }
    }


    &:invalid {
      color: var(--tui-text-negative);
      background: var(--tui-status-negative-pale);

      .appearance-hover {
        color: var(--tui-text-negative-hover);
        background: var(--tui-status-negative-pale-hover);
      }

      .appearance-active {
        color: var(--tui-text-negative-hover);
        background: var(--tui-status-negative-pale-hover);
      }
    }
  }
}

tui-avatar[data-size='m'], tui-avatar[data-size='l'], tui-avatar[data-size='xl'] {

  font-weight: 400 !important;
}

tui-dialog[data-appearance='quick-add'] {
  margin: 1.25rem auto;
  min-width: 75vw;
}

tui-dialog[data-appearance='quick-view'] {
  margin: 1.25rem auto;
  min-width: 70vw;
}

[tuiWrapper][data-appearance='textfield'] {
  box-shadow: 0 0.1rem 0.1rem rgba(92, 92, 92, 0.09);

  &:hover:not(._no-hover) {
    box-shadow: 0 0.125rem 0.325rem rgba(19, 19, 19, 0.12);
  }
}


.t-input .t-content tui-tag[data-status="default"] {
  background-color: var(--m4p-primary-500);
  color: var(--m4p-primary-contrast-500);

  &:hover {
    background-color: var(--m4p-accent-700);
    color: var(--m4p-accent-contrast-700);
  }

  &:active {
    background-color: var(--m4p-primary-700);
    color: var(--m4p-primary-contrast-700);
  }
}


.t-cell[data-state="hover"] {
  color: var(--tui-background-neutral-1-hover-contrast);
}

.t-cell[data-state="hover"]:hover:not([data-range]) > .t-item {

  color: var(--tui-background-neutral-1-hover-contrast);
}

[tuiWrapper][data-appearance='danger'] {
  background: var(--m4p-danger-500);
  color: var(--m4p-danger-contrast-500);

  &:hover:not(._no-hover) {
    background: var(--m4p-danger-700);
  }

  &:active:not(._no-hover) {
    background: var(--m4p-danger-200);
  }
}

[tuiAppearance][data-appearance='danger'] {
  background: var(--m4p-danger-500);
  color: var(--m4p-danger-contrast-500);

  &:hover:not(._no-hover) {
    background: var(--m4p-danger-700);
  }

  &:active:not(._no-hover) {
    background: var(--m4p-danger-200);
  }
}

[tuiAppearance][data-appearance='warning'] {
  background: var(--m4p-warn-500);
  color: var(--m4p-warn-contrast-500);

  &:hover:not(._no-hover) {
    background: var(--m4p-warn-700);
  }

  &:active:not(._no-hover) {
    background: var(--m4p-warn-200);
  }
}

[tuiAppearance][data-appearance="warning"]::before {
  color: var(--m4p-warn-contrast-500);
}

tui-editor [tuiAppearance][data-focus="true"] {
  outline-color: var(--m4p-primary-500);
}


